<template>
  <div class="">
    <span> </span>
    <v-menu
      :close-on-content-click="false"
      v-model="menu"
      :disabled="!permissions.create && !isAdmin"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-chip class="cursor-pointer" v-if="data.team" small color="indigo" dark>
            {{ data.team }}
          </v-chip>
          <v-btn v-else-if="permissions.create || isAdmin" color="primary" icon v-on="on"
            ><v-icon>{{ actionIcon }}</v-icon></v-btn
          >
        </span>
      </template>
      <v-card width="300">
        <v-container>
          <v-text-field v-model="parcel.team" label="Team" outlined></v-text-field>

          <v-btn color="primary" @click="update()"
            >{{ data.team ? 'Update' : 'Save' }} <v-icon>mdi-content-save</v-icon></v-btn
          >

          <span @click="menu = false">
            <cancel-button></cancel-button>
          </span>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Parcel from '../../services/parcel';
export default {
  props: ['data'],
  computed: {
    ...mapState(['permissions']),
    ...mapGetters(['isAdmin']),
    actionIcon() {
      if (this.data.team) {
        return 'mdi-pencil';
      } else {
        return 'mdi-plus-circle';
      }
    }
  },
  components: {
    CancelButton: () => import('@/components/Core/CancelButton')
  },
  data: () => ({
    menu: false,
    parcel: {}
  }),
  mounted() {
    if (this.data) {
      this.parcel = { ...this.data };
    }
  },
  methods: {
    async update() {
      this.$emit('input', this.parcel.team);
      const p = new Parcel({ id: this.data.id });
      p.fetch().then(() => {
        p.update({ team: this.parcel.team });

        this.menu = false;
        this.updateMetrics({
          feature: 'parcel',
          action: 'update',
          parcelId: this.data.id
        });
      });
    }
  }
};
</script>

<style></style>
