import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('span'),_c(VMenu,{attrs:{"close-on-content-click":false,"disabled":!_vm.permissions.create && !_vm.isAdmin},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(_vm.data.team)?_c(VChip,{staticClass:"cursor-pointer",attrs:{"small":"","color":"indigo","dark":""}},[_vm._v(" "+_vm._s(_vm.data.team)+" ")]):(_vm.permissions.create || _vm.isAdmin)?_c(VBtn,_vm._g({attrs:{"color":"primary","icon":""}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.actionIcon))])],1):_vm._e()],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"width":"300"}},[_c(VContainer,[_c(VTextField,{attrs:{"label":"Team","outlined":""},model:{value:(_vm.parcel.team),callback:function ($$v) {_vm.$set(_vm.parcel, "team", $$v)},expression:"parcel.team"}}),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.update()}}},[_vm._v(_vm._s(_vm.data.team ? 'Update' : 'Save')+" "),_c(VIcon,[_vm._v("mdi-content-save")])],1),_c('span',{on:{"click":function($event){_vm.menu = false}}},[_c('cancel-button')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }